$pink:#e91e63;

html,
body,
#root,
#root>div {
  height: 100%;
  overflow-y: hidden;
}

body {
  background-color: #FDFDFD;
}

#root>div {
  display: flex;
  flex-direction: column;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.nav-tabs.nav-pills.nav-tabs {
  border-bottom: 0;
}

.tab-content {
  flex: 1;
  overflow-y: auto;
}

.card {
  margin-top: 8px;
  margin-bottom: 8px;
}


.card .table {
  margin-bottom: 0;

  td {
    vertical-align: middle;
  }

  td.td--button {
    padding-left: 0;
    padding-top: 4px;
    padding-bottom: 4px;
    text-align: right;
  }
}

.item-name {
  line-height: 20px;
}
.item-details {
  line-height: 15px;
}

a.nav-pink {
  color: $pink;
}


.nav-pills .nav-link.nav-pink.active {
  color: #fff;
  background-color: $pink;
  border-color: transparent;
}



.nav-item.nav--with-label {
  display: flex;
  flex-direction: row;
  text-align: left;
  padding: 0;
  overflow: hidden;
  // border-color: #e9ecef;

  .tab__name {
    flex: 1;
    padding: 8px 12px;
  }

  .tab__label {
    padding: 8px 12px;
  }

  &.active {
    .tab__label {
    background-color: rgba(0, 0, 0, 0.1);
    }
  }
}